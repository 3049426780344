import React, { useEffect, useState } from "react";
import styles from "./Scrollbar.module.css";
// @ts-ignore
import debounce from "lodash.debounce";

type ScrollbarProps = {};

export const Scrollbar: React.FC<ScrollbarProps | null | any> = () => {
  const [width, setWidth] = useState(0);

  useEffect(() => {
    window.onscroll = debounce(function () {
      let scrollPercent =
        (window.scrollY * 100) /
        (document.body.scrollHeight - window.innerHeight);
      setWidth(scrollPercent);
    }, 10);
  });

  return (
    <div
      className={`z-50 h-[8px] sticky top-0 bg-coinage-offWhite ${styles.scrollbarContainer} backdrop-blur-md bg-coinage-offWhite/60`}>
      <div
        className={`${styles.scrollbar} bg-coinage-teal w-1/2 h-full`}
        style={{ width: `${width}%` }}></div>
    </div>
  );
};
