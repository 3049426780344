import apiBase, { getApi, postApi, putApi } from "./getApiBase";
import { jwtSign } from "./jwt";

type LookupArgs = {
  lookup: "email" | "wallet" | "referral" | "codeword";
  id: string;
};

export const validateRefferal = async (referralCode: string) => {
  const encrypted = jwtSign({ referralCode });
  const referralRes = await postApi("validate-referral", encrypted);

  return await referralRes.json();
};
export const lookupUserByWallet = async (wallet: string) => {
  const encrypted = jwtSign({ wallet });

  const updatedUser = await postApi("wallet", encrypted);

  return updatedUser.json();
};

export const getReferrals = async (id: number) => {
  const encrypted = jwtSign({ id });
  const referrals = await postApi(`get-referals`, encrypted);

  return referrals.json();
};

export const isWhitelisted = async (wallet: string) => {
  const encrypted = jwtSign({ wallet });
  // todo check this
  const whitelisted = await postApi(`is-whitelisted`, encrypted);

  return whitelisted.json();
};

type User = {
  id: Number;
  trusted?: boolean;
  caucusAllow?: true;
  email?: string;
};
export const updateUser = async (user: User) => {
  const encrypted = jwtSign({ user });

  const updatedUser = await postApi(`user/update`, encrypted);

  return updatedUser.json();
};

export const caucusAllow = async (user: {
  id: Number;
  email?: string;
  acceptsMarketing: boolean;
}) => {
  const encrypted = jwtSign({ user });

  const updatedUser = await postApi(`caucus/allow`, encrypted);

  return updatedUser.json();
};
