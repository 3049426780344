import { FormEvent, useState } from "react";
import { addEmail } from "../utils/api/addMarketingEmail";
import { EmailInput } from "./Inputs/Email";
import Success from "../assets/CheckCircle.svg";
import Error from "../assets/XCircle.svg";
import { Button } from "./Actions";
import cx from "classnames";
import { WalletMarketing } from "./Inputs/WalletMarketing";

type InputProps = {
  title: string;
  containerClassName?: string;
  titleClassName?: string;
  subscribeLocation?: string;
  formClassName?: string;
};
export const EmailSignup: React.FC<InputProps> = ({
  title,
  containerClassName,
  titleClassName,
  subscribeLocation,
  formClassName
}) => {
  const [status, setStatus] = useState<{
    state: null | "success" | "error" | "loading";
    message: string;
  }>({ state: null, message: "" });

  const shouldButtonBeDisabled = () => {
    if (!status.state) return false;
    return ["loading", "error"].includes(status.state);
  };

  const handleSubmit = async (evt: FormEvent<HTMLFormElement>) => {
    evt.preventDefault();
    if (status.state === "error") return;
    setStatus({ state: "loading", message: "" });

    //@ts-expect-error
    const email = evt.target.email.value;
    //@ts-expect-error
    const walletAddress = evt.target.wallet.value;

    const { data, error } = await addEmail(
      email,
      subscribeLocation ?? "subscribe",
      walletAddress
    );
    if (error) {
      return setStatus({
        state: "error",
        message:
          "Sorry something went wrong, please try again or email contact@trustless.media"
      });
    }
    if (data && !error) {
      return setStatus({
        state: "success",
        message: "Thank you. We'll be in touch."
      });
    }
  };
  return (
    <div className={containerClassName}>
      <p className={cx("mb-4", titleClassName)}>{title}</p>
      <form
        noValidate
        onSubmit={handleSubmit}
        className={cx("flex max-w-[500px] gap-2 flex-col", formClassName)}>
        <EmailInput
          status={status}
          setStatus={setStatus}
          inputClassName="px-4 py-2 border-slate-50 flex-1 tab:w-[300px] focus:outline-slate-400 text-coinage-black"
        />
        <WalletMarketing inputClassName="px-4 py-2 border-slate-50 flex-1 tab:w-[300px] focus:outline-slate-400 text-coinage-black" />
        <Button
          analyticsProperty="email subscribe"
          modifier="primary"
          className="w-auto"
          type="submit"
          loading={status.state === "loading"}
          disabled={shouldButtonBeDisabled()}>
          Sign Up
        </Button>
      </form>
      {status.state && (
        <div className="min-h-[60px] flex gap-2 text-coinage-black items-center">
          {status.state === "error" && <Error width="24" />}
          {status.state === "success" && <Success width="24" />}
          <p className="text-sm">{status.message}</p>
        </div>
      )}
    </div>
  );
};
