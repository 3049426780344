"use client";
import { forwardRef, MouseEventHandler, PropsWithChildren } from "react";
import Spinner from "../LoadingIndicators/Spinner";

import { ButtonModifiers, contentClassName, getButtonStyles } from "./shared";

export interface BtnProps extends React.ComponentPropsWithoutRef<"button"> {
  analyticsProperty?: string;
  modifier: ButtonModifiers;
  loading?: boolean;
}

export const Button = forwardRef<
  HTMLButtonElement,
  PropsWithChildren<BtnProps>
>(
  (
    {
      analyticsProperty,
      modifier,
      children,
      className,
      loading,
      type = "button",
      ...props
    },
    ref
  ) => {
    return (
      <button
        ref={ref}
        data-splitbee-event={analyticsProperty}
        className={getButtonStyles(modifier, className)}
        type={type}
        {...props}>
        <span className={contentClassName}>
          {loading ? (
            <Spinner
              textColorClassName={
                modifier === "primary"
                  ? "text-coinage-white"
                  : "text-coinage-blue"
              }
            />
          ) : (
            children
          )}
        </span>
      </button>
    );
  }
);

Button.displayName = "Button";
