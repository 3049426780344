import { PropsWithChildren, FC } from "react";
import { Footer } from "../Footer/Footer";
import HeaderBase from "../Header/HeaderBase";
import MarketingNav from "../Header/MarketingNav";

const Layout: FC<PropsWithChildren<{ hideFooterNewsletter?: boolean }>> = ({
  children,
  hideFooterNewsletter
}) => {
  return (
    <>
      <HeaderBase showMobileMenu>
        <MarketingNav />
      </HeaderBase>
      <main>{children}</main>

      <Footer hideNewsletter={hideFooterNewsletter} />
    </>
  );
};

export default Layout;
