export const links = {
  discord: "https://discord.gg/coinage-953028429211660349",
  youtube: "https://www.youtube.com/channel/UCLaa5cNN89IN0aLfiz5S49A",
  instagram: "https://www.instagram.com/coinage.media/",
  twitter: "https://twitter.com/coinage_media",
  newsletter: "https://coinage.substack.com/",
  charmverse: "https://app.charmverse.io/CoinageMedia",
  telegram:
    "https://telegram.me/collablandbot?start=VFBDI1RFTCNDT01NIy0xMDAyMzcyNTI2MDQ1"
};
