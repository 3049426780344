import {
  PropsWithChildren,
  FC,
  createContext,
  useState,
  useContext,
  useEffect
} from "react";
import { useAccount } from "wagmi";
import { lookupUserByWallet } from "../utils/api/users";
import { identify } from "../utils/identify";

type User = {
  id?: number;
  referralCode?: string;
  walletAddress?: string;
  email?: string;
  hasMinted?: boolean;
  groupId?: number;
  numInvites?: number;
};

type UserContextValue = {
  user: User | null;
  updateUserState: (user: Partial<User>) => void;
};

export const UserContext = createContext<UserContextValue>({
  user: null,
  updateUserState: () => {}
});

export const useUserContext = () => useContext(UserContext);

const User: FC<PropsWithChildren<{}>> = ({ children }) => {
  const [user, setUser] = useState<UserContextValue["user"]>(null);
  const { address } = useAccount();

  // We set what we know about the user on context as they connect the wallet
  const setUserOnContext = async (wallet: string) => {
    const { data: user } = await lookupUserByWallet(wallet);
    if (user) {
      identify({ id: user.id });
      setUser(user);
    }
  };

  useEffect(() => {
    if (address) {
      identify({ wallet: address });
      setUserOnContext(address);
    }
  }, [address]);

  const updateUserState = (userPropsToUpdate: Partial<User>) => {
    if (!user) setUser(userPropsToUpdate);
    setUser({ ...user, ...userPropsToUpdate });
  };
  return (
    <UserContext.Provider value={{ user, updateUserState }}>
      {children}
    </UserContext.Provider>
  );
};

export default User;
