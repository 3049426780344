import jwt from "jsonwebtoken";

export const jwtSign = (data: any) => {
  const encrypted = jwt.sign(
    data,
    process.env.NEXT_PUBLIC_API_ACCESS_TOKEN_SECRET as string
  );

  return { signedData: encrypted };
};
