import "../styles/globals.css";
import "../styles/typography.css";
import "@rainbow-me/rainbowkit/styles.css";
import type { AppProps } from "next/app";
import { RainbowKitProvider, getDefaultConfig } from "@rainbow-me/rainbowkit";
import { CustomAvatar } from "../components/Avatar/Avatar";
import { WagmiProvider, http } from "wagmi";
import { base } from "viem/chains";
import { injected } from "wagmi/connectors";

import { mainnet } from "wagmi/chains";

import Layout from "../components/Layouts/default";
import { Scrollbar } from "../components/Scrollbar/Scrollbar";

import { ReactElement, ReactNode, useEffect } from "react";
import { NextPage } from "next";
import User from "../contexts/User";
import { RainbowTheme } from "../styles/rainbowTheme";
import Script from "next/script";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { alchemyBaseUrl, alchemyMainnetUrl } from "@/utils/alchemy";

export type NextPageWithLayout<P = {}, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

const config = getDefaultConfig({
  appName: "Coinage",
  projectId: "3c07bcacc60df8ec9ce8b3ebf53ccc1e",
  chains: [mainnet, base],
  transports: {
    [mainnet.id]: http(alchemyMainnetUrl),
    [base.id]: http(alchemyBaseUrl)
  }
});

const queryClient = new QueryClient();

// hardhat.id = 1337;

const App = ({ Component, pageProps }: AppPropsWithLayout) => {
  const getLayout =
    Component.getLayout ||
    (page => {
      return <Layout>{page}</Layout>;
    });

  return (
    <>
      <WagmiProvider config={config}>
        <QueryClientProvider client={queryClient}>
          <RainbowKitProvider theme={RainbowTheme} avatar={CustomAvatar}>
            <Script src="https://www.googletagmanager.com/gtag/js?id=UA-245361658-1" />
            <Script
              id="google-tag-manager"
              dangerouslySetInnerHTML={{
                __html: `
        window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());

  gtag('config', 'UA-245361658-1');
      `
              }}
            />

            <Script
              id="fb"
              dangerouslySetInnerHTML={{
                __html: `
        !function(f,b,e,v,n,t,s)
{if(f.fbq)return;n=f.fbq=function(){n.callMethod?
n.callMethod.apply(n,arguments):n.queue.push(arguments)};
if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
n.queue=[];t=b.createElement(e);t.async=!0;
t.src=v;s=b.getElementsByTagName(e)[0];
s.parentNode.insertBefore(t,s)}(window, document,'script',
'https://connect.facebook.net/en_US/fbevents.js');
fbq('init', '1143702962923094');
fbq('track', 'PageView');
      `
              }}
            />
            <Script src="/twt.js" strategy="lazyOnload" />
            <Scrollbar />

            <User>{getLayout(<Component {...pageProps} />)}</User>
          </RainbowKitProvider>
        </QueryClientProvider>
      </WagmiProvider>
    </>
  );
};

export default App;
