import cx from "classnames";

export type ButtonModifiers = "primary" | "secondary" | "tertiary";
export type LinkModifiers = ButtonModifiers;

export const contentClassName = "translate-y-[2px] z-10";
const sharedClassNames =
  "px-6 py-2 inline-flex btn border-coinage-blue disabled:cursor-not-allowed text-center min-w-[120px] min-h-[42px] justify-center w-full tab:w-max max-w-[500px] transition-all";

const buttonClassNamesPerModifier = {
  primary:
    "rounded-3xl border-[1px] btn-hover primary bg-coinage-blue text-coinage-offWhite active:border-coinage-blueAlt active:bg-coinage-blueAlt disabled:border-coinage-body disabled:bg-coinage-body",
  secondary:
    "rounded-3xl border-[1px] btn-hover secondary bg-coinage-offWhite text-coinage-blue active:bg-coinage-blueAltLight disabled:text-coinage-body disabled:border-coinage-body",
  tertiary:
    "border-0 border-solid rounded-none border-b-[1px] flex text-coinage-blue gap-2 px-0 py-0 pb-1 items-center min-w-[1px] border-transparent hover:border-current hover:border-b-[1px] min-h-fit w-max"
};

export const getButtonStyles = (
  modifier: ButtonModifiers,
  className?: string
) => {
  return cx(sharedClassNames, buttonClassNamesPerModifier[modifier], className);
};

export const getLinkStyles = (modifier: LinkModifiers, className?: string) => {
  return cx(sharedClassNames, buttonClassNamesPerModifier[modifier], className);
};
