import { FC } from "react";

import Link from "next/link";
import { links } from "../../utils/links";

const MarketingNav: FC = () => {
  return (
    <nav
      className="flex justify-center gap-8 btn text-coinage-black 
    [&>a]:whitespace-nowrap">
      <Link data-splitbee-event="Navigation: About" href="/watch">
        Stories
      </Link>
      <Link data-splitbee-event="Navigation: About" href="/about">
        About
      </Link>
      <Link
        target="_blank"
        data-splitbee-event="Navigation: SBF Trial"
        href="https://daic.capital/?utm_source=coinage&utm_medium=website&partner=b4cnuqzdt6">
        Stake With Us
      </Link>
      <Link
        data-splitbee-event="Navigation: Newsletter"
        href={links.newsletter}>
        Newsletter
      </Link>
      <Link
        data-splitbee-event="Navigation: Roadmap"
        href="https://shop.coinage.media">
        Shop
      </Link>
    </nav>
  );
};

export default MarketingNav;
