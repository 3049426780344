import { ConnectButton } from "../Actions";

import React, { PropsWithChildren, useEffect, useRef, useState } from "react";
import Logo from "../../assets/CoinageLogo.svg";
import X from "../../assets/x.svg";

import { Link } from "../Actions";
import NextLink from "next/link";

import cx from "classnames";
import { useRouter } from "next/router";
import { links } from "../../utils/links";

type HeaderProps = {
  showMobileMenu: boolean;
  showExitInsteadOfConnect?: boolean;
  transparentHeader?: boolean;
  childClassName?: string;
  hideMintBtn?: boolean;
  hideWalletBtn?: boolean;
  mintBtnText?: string;
};

export const HeaderBase: React.FC<PropsWithChildren<HeaderProps>> = ({
  children,
  showMobileMenu,
  hideMintBtn,
  showExitInsteadOfConnect,
  transparentHeader,
  childClassName,
  hideWalletBtn,
  mintBtnText
}) => {
  const oldValue = useRef(0);
  const scrollDirection = useRef<null | "up" | "down">(null);
  const [trigger, setTrigger] = useState(false);

  const [mobileMenuActive, setMobileMenuActive] = useState(false);
  const router = useRouter();

  useEffect(() => {
    const onScroll = () => {
      let newVal = window.pageYOffset;
      if (oldValue.current + 5 < newVal) {
        // scrolling down the page
        scrollDirection.current = "down";
        oldValue.current = newVal;
        setTrigger(!trigger);
      } else if (oldValue.current > newVal + 5) {
        // scrolling up the page
        scrollDirection.current = "up";
        oldValue.current = newVal;
        setTrigger(!trigger);
      }
    };
    window.removeEventListener("scroll", onScroll);
    window.addEventListener("scroll", onScroll, { passive: true });
    return () => window.removeEventListener("scroll", onScroll);
  }, [trigger]);

  const desktopStyles = cx({
    "hidden tab:flex": showMobileMenu,
    flex: !showMobileMenu
  });
  const isPassedThreshold = oldValue.current > 8;
  const headerClassNames = cx(
    transparentHeader
      ? "bg-transparent"
      : "backdrop-blur-md bg-coinage-offWhite/60",
    "sticky w-full z-50 transition-all  top-[8px]",
    {
      "top-[-100px]": scrollDirection.current === "down" && isPassedThreshold
    }
  );

  const mobileBtnStyles = cx({
    "ml-auto flex tab:hidden  translate-y(-3px)": showMobileMenu,
    hidden: !showMobileMenu
  });

  const mobileMenuStyles = cx({
    flex: showMobileMenu && mobileMenuActive,
    hidden: !mobileMenuActive || !showMobileMenu
  });

  const desktopItems = cx(
    desktopStyles,
    childClassName,
    "mr-auto",
    "hide-scrollbar overflow-scroll"
  );

  useEffect(() => {
    const handleRouteChange = () => {
      setMobileMenuActive(false);
      scrollDirection.current = null;
      oldValue.current = 0;
    };

    router.events.on("routeChangeStart", handleRouteChange);

    // If the component is unmounted, unsubscribe
    // from the event with the `off` method:
    return () => {
      router.events.off("routeChangeStart", handleRouteChange);
    };
  }, []);

  return (
    <>
      <header className={headerClassNames}>
        <div className="max-w-[1200px] mx-auto flex p-4 px-6  items-center gap-8 flex-nowrap">
          <NextLink href="/">
            <Logo width={105} />
          </NextLink>
          <div className={desktopItems}>{children}</div>

          <div className={desktopStyles}>
            {showExitInsteadOfConnect ? (
              <Link analyticsProperty="Exit" href="/" modifier="tertiary">
                Exit
              </Link>
            ) : (
              <div className="flex gap-2 items-center justify-end">
                <Link
                  className={
                    hideMintBtn ? "hidden" : "tab:!max-w-none tab:!w-auto"
                  }
                  modifier="secondary"
                  analyticsProperty="watch"
                  href={mintBtnText === "Join Us" ? "#mint" : "/about#mint"}>
                  {mintBtnText || "MINT"}
                </Link>
                {!hideWalletBtn && <ConnectButton />}
              </div>
            )}
          </div>

          <button
            onClick={() => setMobileMenuActive(!mobileMenuActive)}
            className={mobileBtnStyles}>
            <svg
              width="40"
              height="38"
              viewBox="0 0 25 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M4.25 12H20.75"
                stroke="#1E1C1B"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M4.25 6H20.75"
                stroke="#1E1C1B"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M4.25 18H20.75"
                stroke="#1E1C1B"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
        </div>
      </header>

      <div className={mobileMenuStyles}>
        <div className="fixed bg-coinage-offWhite w-full min-h-screen mobile-menu min-w-screen top-0 left-0 z-50 flex flex-col">
          <div className="flex flex-row mt-6">
            <div className="flex mr-auto ml-6">
              <NextLink href="/">
                <Logo width={105} />
              </NextLink>
            </div>

            <button
              className="flex ml-auto mr-6"
              onClick={() => setMobileMenuActive(!mobileMenuActive)}>
              <X width={40} />
            </button>
          </div>

          <nav className="flex m-auto flex-col mb-10">
            <NextLink
              className={`display3 text-center mb-2 ${
                router.pathname == "/" ? "text-coinage-orange" : ""
              }`}
              href="/">
              HOME
            </NextLink>
            <NextLink
              className={`display3 text-center mb-2 ${
                router.pathname == "/watch" ? "text-coinage-orange" : ""
              }`}
              href="/watch">
              Stories
            </NextLink>
            <NextLink
              className={`display3 text-center mb-2 ${
                router.pathname == "/about" ? "text-coinage-orange" : ""
              }`}
              href="/about">
              ABOUT
            </NextLink>
            <NextLink
              target="_blank"
              className="display3 text-center mb-2"
              data-splitbee-event="Navigation: SBF Trial"
              href="https://daic.capital/?utm_source=coinage&utm_medium=website&partner=b4cnuqzdt6">
              Stake With Us
            </NextLink>
            <NextLink
              className={`display3 text-center mb-2`}
              data-splitbee-event="Navigation: Newsletter"
              href={links.newsletter}>
              Newsletter
            </NextLink>
            <NextLink
              className={`display3 text-center mb-2`}
              href={"https://shop.coinage.media"}>
              SHOP
            </NextLink>
          </nav>

          <div
            className="w-full max-w-[260px] m-auto mt-0 flex flex-col gap-2 
            ">
            <Link
              onClick={() => setMobileMenuActive(false)}
              className={hideMintBtn ? "hidden" : ""}
              modifier="secondary"
              analyticsProperty="watch"
              href={mintBtnText === "Join Us" ? "#mint" : "/about#mint"}>
              {mintBtnText || "MINT"}
            </Link>
            {!hideWalletBtn && <ConnectButton />}
          </div>
        </div>
      </div>
    </>
  );
};

export default HeaderBase;
