/* eslint-disable @next/next/no-img-element */
import { AvatarComponent } from "@rainbow-me/rainbowkit";
import avatar from "./avatar.png";

const className = "rounded-full";
export const CustomAvatar: AvatarComponent = ({ address, ensImage, size }) => {
  return ensImage ? (
    <img
      alt={`Avatar for ${address}`}
      src={ensImage}
      width={size}
      height={size}
      className={className}
    />
  ) : (
    <img
      src={avatar.src}
      alt={`Avatar for ${address}`}
      className={className}
      width={size}
      height={size}
    />
  );
};
