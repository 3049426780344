import styles from "./Footer.module.css";
import { EmailSignup } from "../EmailSignup";
import NextImage from "next/legacy/image";
import cx from "classnames";
import TrustlessLogo from "../../assets/trustlessLogo.png";

import { Link } from "../Actions";
import { SocialIconGroup } from "../SocialIconGroup/SocialIconGroup";

export const Footer: React.FC<{ hideNewsletter?: boolean }> = ({
  hideNewsletter
}) => {
  return (
    <div className="bg-coinage-offWhite">
      <div className="content-wrapper">
        <div className={styles.footerWrapper}>
          <div
            className={cx("p-3 pb-3", styles.innerWrapper, {
              "!hidden": hideNewsletter
            })}>
            <h3 className="display3">
              STAY UP-TO-DATE WITH THE STORY OF CRYPTO.
            </h3>
            <div>
              <EmailSignup title="" />
            </div>
          </div>

          <div className={styles.bottomWrapper}>
            <div
              className={`${styles.socialWrapper} tab:pt-[32px] mx-auto max-w-[90%] w-full pb-[15px] border-b-[1px] tab:border-none border-coinage-gray ml-[20px] flex flex-col tab:flex-row tab:ml-auto gap-2`}>
              <p className="mono2 text-coinage-gray mb-[15px] tab:flex tab:gap-[10px] tab:flex-row tab:ml-auto mt-auto tab:mb-auto tab:mr-[10px]">
                <Link
                  modifier="tertiary"
                  className="!p-0"
                  analyticsProperty="privacy"
                  href="/roadmap#FAQ">
                  FAQs
                </Link>{" "}
                <Link
                  className="!p-0"
                  modifier="tertiary"
                  analyticsProperty="privacy"
                  href="/press">
                  PRESS
                </Link>{" "}
              </p>

              <SocialIconGroup className="w-[200px]" />
            </div>
            <div className={`${styles.privacyWrapper} m-4`}>
              <p>From:</p>
              <div
                className={`${styles.trustlessWrapper} flex flex-col tab:flex-row`}>
                <div className="w-[170px] mr-[30px] ml-0 mb-[15px] tab:mb-0">
                  <a href="https://www.trustless.media">
                    <NextImage src={TrustlessLogo} alt="trustless media logo" />
                  </a>
                </div>

                <div className="flex gap-4 flex-wrap">
                  <p className="mono2 text-coinage-gray">
                    <Link
                      className="!p-0"
                      modifier="tertiary"
                      analyticsProperty="privacy"
                      href="/privacy">
                      PRIVACY POLICY
                    </Link>
                  </p>
                  <p className="mono2 text-coinage-gray">
                    <Link
                      className="!p-0"
                      modifier="tertiary"
                      analyticsProperty="terms"
                      href="/terms">
                      TERMS &amp; CONDITIONS
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
